<template>
	<edit-template class="add-grade" @confirm="saveData" @cancel="cancelData" :cancelRemind="false" confirmText="保存" cancelText="取消">

    <div class="form-body">
      <el-form ref="form" :model="form" :rules="rules" label-width="150rem">
        <el-form-item label="毕业年份" prop="name">
          <p>{{ form.name }}</p>
          <!--          <el-select :popper-append-to-body="false" v-model="form.name" placeholder="请选择" class="select">-->
          <!--            <el-option v-for="item in gradeList" :key="item" :label="item" :value="item"></el-option>-->
          <!--          </el-select>-->
        </el-form-item>

        <el-form-item label="入学年份" prop="enrollment">
          <p>{{ form.enrollment }}</p>
          <!--          <el-select :popper-append-to-body="false" v-model="form.enrollment" placeholder="请选择" class="select">-->
          <!--            <el-option v-for="item in classList" :key="item" :label="item" :value="item"></el-option>-->
          <!--          </el-select>-->
        </el-form-item>
        <el-form-item label="入学时间" format="HH:mm:ss" prop="admission_time">
          <el-date-picker v-model="form.admission_time" value-format="yyyy-MM-dd" type="date" placeholder="请选择"></el-date-picker>
        </el-form-item>
        <el-form-item label="开学时间" format="HH:mm:ss" prop="opening_time">
          <el-date-picker v-model="form.opening_time" value-format="yyyy-MM-dd" type="date" placeholder="请选择"></el-date-picker>
        </el-form-item>
        <el-form-item label="毕业时间" prop="graduation_time">
          <el-date-picker v-model="form.graduation_time" value-format="yyyy-MM-dd" type="date" placeholder="请选择"></el-date-picker>
        </el-form-item>
        <!-- 面板展示 -->
        <el-descriptions class="margin-top" :column="3" border="" style="minwidth: 500rem; margin: 50rem 50rem 50rem 50rem">
          <el-descriptions-item style="width: 150rem">
            <template slot="label">创建人:</template>
            {{ form.creator == '' ? '' : form.creator }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">创建时间:</template>
            {{ form.created_at == '' ? '' : form.created_at }}
          </el-descriptions-item>
        </el-descriptions>
      </el-form>
    </div>


</edit-template>
</template>

<script>
//引入ajax接口管理层的接口函数
import {editGrade} from '@/api/grade'

export default {
	_config:{"route":{"path":"edit","meta":{"title":"编辑"}}},
  data() {
    return {
      form: {
        name: '',  //毕业年份
        enrollment: '',  //入学年份
        admission_time: '',  //入学时间
        opening_time: '',  //开学时间
        graduation_time: ''  //毕业时间
      },
      gradeList: [],
      classList: [],
      rules: {
        name: [{required: true, message: '请输入毕业年份', trigger: 'change'},],
        enrollment: [{required: true, message: '请输入入学年份', trigger: 'change'},],
        opening_time: [{required: true, message: '请输入开学时间', trigger: 'change'},],
        admission_time: [{required: true, message: '请选择入学时间', trigger: 'change'}],
        graduation_time: [{required: true, message: '请选择毕业时间', trigger: 'change'}],
      },
      value: '',
      value1: '',
      value2: ''
    }
  },
  methods: {
    //  获取从列表传过来的数据
    async getdetailsData() {
      this.$_axios.get('setup/grade-details', {params: {id: this.$route.query.id}}).then(res => {
        this.form = res.data.data
      })
      //   (this.form)
    },

    //计算毕业年份
    getYear() {
      let year = new Date().getFullYear()
      for (let i = 0; i < 5; i++) {
        let str1 = year + '届'
        let str2 = year + '级'
        this.gradeList.push(str1)
        this.classList.push(str2)
        year++
      }
    },

    async saveData() {
      editGrade(this.form).then(res => {
        this.$message({
          message: '编辑成功',
          type: 'success'
        })
        this.$router.push('./list')
      }).catch(() => {

      })
    },

    cancelData() {
      this.$confirm('是否取消编辑', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$router.back()
      })
    }
  },
  created() {
    this.getYear()
    this.getdetailsData()
  }
}
</script>

<style lang="scss" scoped>
.el-button--primary {
  background-color: #1d2088;
}

.lowerbtn {
  background-color: rgba(204, 204, 204, 0.39);
  // width: 60rem;
  height: 40rem;
  text-align: center;
  line-height: 20rem;
  // line-height: 40rem;
  color: #333333;
  border: 0;
  box-sizing: border-box;
}

.createtitle {
  width: 100%;
  padding-left: 30rem;
  margin-top: 20rem;
  margin-bottom: 30rem;
  height: 150rem;
  background-color: rgba(250, 250, 250);

  .createtitle_item {
    height: 75rem;
    display: flex;
    align-items: center;
  }
}
</style>
