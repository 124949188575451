/*  年级管理接口   */

import request from '@/utils/request'


// 获取年级列表
export const getGradelist = (page = 1) => {
    return request({
        method: 'get',
        url: '/setup/grade' + '?page=' + page,

    })
}

// 新增年级
export const addGrade = (data) => {
    return request({
        method: 'post',
        url: '/setup/grade-add',
        data
    })
}
// 编辑年级
export const editGrade = (data) => {
    return request({
        method: 'post',
        url: '/setup/grade-edit',
        data
    })
}


// 获取年级列表
